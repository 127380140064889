<template>
  <div class="container">
    <div class="container-con">
      <section class="m-dxby-fixed">
        <!-- Header S -->
        <Head
          :backIsShow="true"
        />
        <!-- Header E -->
        <!-- Nav S -->
        <Nav/>
        <!-- Nav E -->
      </section>
      <section style="height: 120px"></section>
      <!-- content S -->
      <section class="m-dxby-news-con">
        <ul>
          <li
            v-for="(item, key) in artListData"
            :key="key"
          >
            <!-- <router-link :to="{path: `/news_details?id=${item.id}`}"> -->
            <a :href="item.url">
              <p>
                <i
                  v-if="key < 3"
                  class="m-dxby-toppingIcon"
                >
                  <img width="18" :src="toppingIcon" alt="">
                </i>
                <i v-else>>></i>
                <span :style="{color: key < 3 ? '#d81e03': '', fontWeight: key < 3 ? 'bold': ''}">{{item.title}}</span>
              </p>
              <span :style="{color: key < 3 ? '#d81e03': '', fontWeight: key < 3 ? 'bold': ''}">[ {{item.createTime.slice(0, 10)}} ]</span>
            </a>
          </li>
        </ul>
        <div class="m-dxby-loading">{{loadingText}}</div>
      </section>
      <!-- content E -->
    </div>
    <Foot/>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Nav from '@/components/Nav'
import Foot from '@/components/Foot'
import metaLable from '@/utils/metaLable'
import axios from 'axios'
import { hostApi } from '@/utils/requestApi'
import { TOPPING_ICON as toppingIcon } from '@/utils/imageManage'
// import { Toast } from 'vant'

export default {
  name: 'News',
  metaInfo: {
    title: '大新布业 - 最新动态',
    meta: metaLable
  },
  data () {
    return {
      pageNum: 1,
      pageSize: 12,
      artListData: [],
      toppingIcon,
      totals: 0,
      loadingText: '加载中...'
    }
  },
  components: {
    Head,
    Nav,
    Foot
  },
  methods: {
    async getArtList () {
      let _data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await axios.post(`${hostApi}/article/list`, _data)
      const { list, total } = res.data
      this.artListData.push(...list)
      this.totals = total
    },
    getOnscrollFresh () {
      window.addEventListener('scroll', () => {
        let _sceollVal = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight
        // 滚动条到底部的条件
        if (_sceollVal >= 120) {
          // 写后台加载数据的函数
          if (this.artListData.length >= this.totals) {
            this.loadingText = '- 到底了-'
            return
          }
          this.pageNum++
          this.getArtList()
        }
      })
    }
  },
  created () {
    this.getOnscrollFresh()
  },
  mounted () {
    this.getArtList()
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
// 加载中
.m-dxby-loading {
  text-align: center;
  color: #333;
  .fsize(14px);
  padding: 10px 0;
  margin-bottom: 10px;
}
.m-dxby-fixed {
  .w(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.m-dxby-news-con {
  margin: 20px 12px 0;
  ul {
    // margin-bottom: 40px;
    li {
      .h(60px);
      .lht(60px);
      border-bottom: 1px dotted #333;
      a {
        display: block;
        .w(100%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        p {
          .w(60%);
          .fsize(14px);
          .otx(1);
          color: #333;
          i {
            margin-right: 5px;
          }
          i.m-dxby-toppingIcon {
            .wh(20px, 20px);
            display: inline-block;
            position: relative;

            img {
              position: absolute;
              top: 7px;
              left: 0;
            }
          }
        }
        span {
          color: #333;
          .fsize(14px);
        }
      }
    }
    li:last-child {
      border-bottom: 0
    }
  }
}
</style>
